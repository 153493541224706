<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入优惠券名称"
            class="search-input ml-10"
            v-model="searchObj.name"
          ></el-input>
          <el-input
            clearable
            placeholder="请输入手机号"
            class="search-input ml-10"
            v-model="searchObj.phone"
          ></el-input>
          <el-select placeholder="请选择门店" class="search-input ml-15" v-model="searchObj.storeId" @change="changeTab">
            <el-option
              v-for="(item,index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select v-model="searchObj.merchantId" filterable placeholder="请选择商户" class="search-input ml-15">
            <el-option
              v-for="item in merchantList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId">
            </el-option>
          </el-select>
          <el-date-picker
            class="ml-10"
            v-model="searchObj.date"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button type="primary" icon="el-icon-search" class="ml-10" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="餐饮优惠券码" prop="qrCode"></el-table-column>
            <el-table-column label="商户名称" prop="storeName"></el-table-column>
            <el-table-column label="所属门店" >
              <template slot-scope="scope">
                {{scope.row.storeName?scope.row.storeName:'--'}}
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="nickname"></el-table-column>
            <el-table-column label="优惠券名称" prop="couponName"></el-table-column>
            <el-table-column label="核销人员" prop="name"></el-table-column>
            <el-table-column label="核销时间" prop="photoTime">
              <template slot-scope="scope">{{scope.row.photoTime?scope.row.photoTime:'--'}}</template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {getStoreList, getMerchantsList} from "@/api/online_department_store/commodity_manage"
import {getRepastCouponList} from "@/api/verify_center/verify_records"
export default {
  components: { Pagination },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        phone: "",
        storeId: null,
        orderType: null,
        date: [],
        name: "",
        merchantId: ''
      },
      store_list: [],
      merchantList: [],
      order_type: [
        {
          id: 1,
          title: "正常交易"
        },
        {
          id: 2,
          title: "积分活动"
        },
        {
          id: 3,
          title: "砍价活动"
        },
        {
          id: 5,
          title: "商城"
        },
        {
          id: 6,
          title: "多人拼团"
        },
        {
          id: 7,
          title: "限时抢购"
        }
      ],
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      order_status: [
        {
          id: 0,
          title: "未支付"
        },
        {
          id: 1,
          title: "已支付"
        },
        {
          id: 2,
          title: "已发货"
        },
        {
          id: 3,
          title: "已完成"
        },
        {
          id: 4,
          title: "待退款"
        },
        {
          id: 5,
          title: "已退款"
        }
      ]
    };
  },
  mounted() {
    this.getStores();
    this.initData();
  },
  methods: {
    // 初始化数据
    initData(searchData) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      data = Object.assign(data, searchData);
      getRepastCouponList(data).then(res => {
        this.tableData = res.data.body.list;
        this.$set(this, "tableData", res.data.body.list);
        this.total = res.data.body.total;
      })
    },
    // 搜索事件
    search() {
      let data = {
        storeId: this.searchObj.storeId,
        phone: this.searchObj.phone,
        orderType: this.searchObj.orderType,
        name: this.searchObj.name,
        merchantId: this.searchObj.merchantId,
        photoTime1: this.searchObj.date && this.searchObj.date[0],
        photoTime2: this.searchObj.date && this.searchObj.date[1]
      };
      // this.$message("点击查询");
      this.initData(data);
    },

    // 重置搜索关键词
    reset() {
      (this.searchObj = {
        phone: "",
        storeId: null,
        orderType: null,
        name:'',
        merchantId: '',
        date: []
      }),
        this.initData();
    },
    // 获取门店列表
    getStores() {
      getStoreList().then(res => {
         this.store_list = res.data.body.list;
      })
    },
     //获取商户列表
    getMerchantsListHandle() {
      getMerchantsList({shopId: this.searchObj.storeId}).then(res => {
        if (res.data.body && res.data.body.list) {
          this.merchantList = res.data.body.list;
        }
      });
    },
    // 门店改变
    changeTab(val) {
      // console.log(val)
      // this.searchObj.storeId = tab.name;
      this.getMerchantsListHandle()
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 0) {
        this.order_status.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
